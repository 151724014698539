import React from "react"
import { css } from "@emotion/core"

import { mediaQueries, container, fonts, weights } from "../../styles"
import Layout from "../../components/layout"
import FullWidthSection from "../../components/FullWidthSection"
import Button from "../../components/Button"
import { navigate } from "gatsby"

const InfrastructureEngineer = () => {
  const wrapperStyles = css`
    padding-top: 30px;
    padding-bottom: 30px;
    font-family: ${fonts.sans};
    font-weight: ${weights.light};
    letter-spacing: 0.2px;

    strong:only-child {
      display: block;
      margin-top: 60px;
      margin-bottom: -7px;
      font-family: inherit;
      font-size: 21px;
      line-height: 1.4px;
      font-weight: ${weights.bold};
    }

    ${mediaQueries.phoneLarge} {
      padding-top: 100px;
      padding-bottom: 100px;

      strong:only-child {
        font-size: 18px;
        line-height: 1.39;
        margin-bottom: -27px;
      }

      ul {
        font-size: 16px;
        line-height: 1.6875;
      }
    }
  `

  return (
    <Layout
      headerData={{
        title: "インフラ系エンジニア",
        mobileMinHeight: "93vh",
        children: (
          <>
            <Button
              css={css`
                margin-top: 64px;

                ${mediaQueries.xs} {
                  margin-top: 72px;
                }
              `}
              onClick={() => navigate("/contact")}
            >
              応募
            </Button>
          </>
        ),
      }}
    >
      <FullWidthSection height="100%" padding="0">
        <div css={[container.medium, wrapperStyles]}>
          <table>
            <tbody>
              <tr>
                <td>職種</td>
                <td>インフラデザイン・構築・運用・保守</td>
              </tr>
              <tr>
                <td>応募資格</td>
                <td>
                  <li>
                    ネットワークエンジニア：国内・海外キャリア系・企業系ネットワーク、設計・構築・運用経験者
                  </li>
                  <li>
                    サーバエンジニア：Linux他サーバシステム、設計・構築・運用経験者
                  </li>
                  <li>
                    ストレージエンジニア：SAN/NASストレージシステム、設計・構築・運用経験者
                  </li>
                  <li>
                    ビッグデータエンジニア：ビッグデータ系システム、設計・構築・運用経験者
                  </li>
                  <li>
                    データアーキテクト：データ分析・統計等、コンサルティング・設計・構築・運用経験者
                  </li>
                </td>
              </tr>
              <tr>
                <td>勤務地</td>
                <td>東京本社（東京都台東区上野3-18-13）</td>
              </tr>
              <tr>
                <td>雇用形態</td>
                <td>
                  <li>正社員</li>
                  <li>契約社員</li>
                </td>
              </tr>
              <tr>
                <td>給与</td>
                <td>スキル・経験に応じ決定</td>
              </tr>
              <tr>
                <td>その他</td>
                <td>
                  スキルにより、プロジェクトリーダー、マネージャへの登用あり
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </FullWidthSection>
    </Layout>
  )
}

export default InfrastructureEngineer
